// src/components/StripeCheckoutButton.js
import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';

// Make sure to replace with your Stripe publishable key
const { 
    REACT_APP_STRIPE_API_URL
} = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_API_URL);

const CheckoutForm = ({ cartItems }) => {
  const stripe = useStripe();
  const elements = useElements();
  console.log( 'in b '+ cartItems)


  const handleCheckout = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenElement ? csrfTokenElement.getAttribute('content') : '';

    // if (!csrfToken) {
    //   console.error('CSRF token not found');
    //   return;
    // }

    // axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    try {
      const { data: { id: sessionId } } = await axios.post(`${process.env.REACT_APP_API_URL}api/ecommerce/v1/create-checkout-session`, { cartItems });

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <form onSubmit={handleCheckout} className='w-100'>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements} className='bg-black text-white btn w-100 mt-2'>
        Pay with Stripe
      </button>
    </form>
  );
};

const StripeCheckoutButton = ({ cartItems }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm cartItems={cartItems} />
    </Elements>
  );
};

export default StripeCheckoutButton;