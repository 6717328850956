import React from 'react'
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import { useState } from 'react';
import BestSellers from './BestSellers';
import GiftSets from './GiftSets';
import Body from './Body';
import { FaShoppingBag } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavBar = () => {
    const navigate = useNavigate(); 
    
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);


    const showHandler = () => {
        setShow(true)
        setShow2(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler2 = () => {
        setShow2(true)
        setShow(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler3 = () => {
        navigate("/search-order");
    }

    const showHandler4 = () => {
        setShow4(true)
        setShow(false)
        setShow2(false)
        setShow3(false)

    }


    const dontShowHandler = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)


    }

    return (
        <div className='mb-4'>
            <header className="banner" role="banner">

                <nav className="navbar d-flex justify-content-start align-items-center" role="navigation" aria-label="menu">

                    <Link to="/">
                        <img src={logo} className=" ml-32" style={{objectFit: 'contain', height: '50px'}}/>
                    </Link>

                    <ul className="menuNav">
                        <li className="dropdown nav-link nav-link-fade-up transition-all duration-700" onMouseOver={showHandler} >
                            BEST SELLERS
                            {show && <div>
                                <ul className="dropdown-nav" onMouseLeave={dontShowHandler} >

                                    <BestSellers > </BestSellers>

                                </ul>

                            </div>}

                        </li >


                        <li className="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler2} >
                            CUSTOMIZE NFC
                            {/* {show2 && <ul className="dropdown-nav dropdown-nav2" onMouseLeave={dontShowHandler}>
                                <GiftSets />
                            </ul>} */}

                        </li>


                        <li className="dropdown nav-link nav-link-fade-up" onClick={showHandler3} >
                            View Order
                        </li>

                        <li className="dropdown nav-link nav-link-fade-up d-flex justify-content-center align-items-center" >
                            <Link to="/cart">
                                <FaShoppingBag className=' text-2xl' />
                            </Link>
                        </li>
                    </ul>

                    
                </nav >
            </header >



        </div >
    )
}

export default NavBar







/*    



          


















             <div className="container">
                <div className="dropdown" onMouseOver={showHandler}>
                    <button className="dropbtn">Dropdown</button>
                    <div className="dropdown-content" onMouseLeave={dontShowHandler}>
                     {show && <BestSellers /> }
                    </div>
                </div>

                <div className="dropdown" onMouseOver={showHandler2}>
                    <button className="dropbtn">Dropdown</button>
                    <div className="dropdown-content" onMouseLeave={dontShowHandler}>
                    {show2 && <GiftSets /> }
                    </div>
                </div>


                <div className="dropdown">
                    <button className="dropbtn">Dropdown</button>
                    <div className="dropdown-content">
                        Link 1
                        Link 2
                        Link 3
                    </div>
                </div>


                <a href="#news">Link</a>
            </div>

            <h3>Dropdown Menu inside a Navigation Bar</h3>
            <p>Hover over the "Dropdown" link to see the dropdown menu.</p>













*/

