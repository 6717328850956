import React from 'react'
import "../styles/HeroSection.css"
import one from "../assets/one.png"
import two from "../assets/two.png"
import three from "../assets/three.png"


const HeroSection = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='pt-4 col-12'>
                <p className='text-center fw-bold fs-2'>WELCOME TO OUR STORE</p>
                </div>
                <div className='pt-4 col-12 w-100 text-center'>
                    <p className='text-xl text-center'> The self care brand that's setting a new standard. Natural products, to help
                    you feel good in your own body. </p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                        <img src={one} className=" w-20" />
                        <p> Powered By Nature </p>
                        <p> Aliquam venenatis vel lorem id <br /> nuaer mollis eget. </p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={two} className=" w-20" />
                    <p> Exceptional Quality </p>
                    <p> Nulla tincidunt gravida augue, eget <br /> pellentesque. </p>

                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={three} className=" w-20" />
                    <p> Skin First Formulas </p>
                    <p> Aliquam sed diam fringilla, loborti <br /> sorci nec </p>
                </div>
            </div>
        </div>
    )
}

export default HeroSection