import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import aus from "../assets/aus.png";
import "../styles/SinglePageFAQ.css";


const SinglePageFAQ = (props) => {
  const {description, how} = props;
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Item eventKey="0">
          <Accordion.Header>PRODUCT INFO</Accordion.Header>
          <Accordion.Body>
            {description}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
      {how && <Card>
        <Accordion.Item eventKey="1">
          <Accordion.Header>HOW TO USE</Accordion.Header>
          <Accordion.Body>
            {how}
          </Accordion.Body>
        </Accordion.Item>
      </Card>}
    </Accordion>
  );
};

export default SinglePageFAQ;
